import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/reactKwik/src/layouts/blogPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We’re excited to release a new and improved `}<a parentName="p" {...{
        "href": "http://info.kwikswap.org/"
      }}>{`info.kwikswap.org`}</a>{` with more detailed information about the Kwikswap ecosystem and token exchanges. For this release we focused providing more complete and accurate data, better UI/UX and the ability to buy and sell tokens with the Kwikswap iframe integration.`}</p>
    <p>{`Keep reading below for a breakdown of the new features.`}</p>
    <hr></hr>
    <h1 {...{
      "id": "overview-page",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview-page",
        "aria-label": "overview page permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview Page`}</h1>
    <p>{`We’ve built a new overview page with high level data about exchanges on Kwikswap. Detailed stats like 24 hour volume, total liquidity and daily transactions are now available at a glance. We’ve also listed the top exchanges sortable by price, volume, liquidity and more to help you explore different aspects of the most active pools.`}</p>
    <h1 {...{
      "id": "exchange-pages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#exchange-pages",
        "aria-label": "exchange pages permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exchange Pages`}</h1>
    <p>{`We’ve updated the design to give you a better overview of the exchange price and performance including 24 hour stats in USD and ETH. The chart view has been improved and shows historical liquidity, volume and price across across a number of timeframes.`}</p>
    <p>{`We now show a sortable and filterable list of of all transactions in the last 24 hours as well.`}</p>
    <h1 {...{
      "id": "buy-any-token-using-kwikswapinfo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#buy-any-token-using-kwikswapinfo",
        "aria-label": "buy any token using kwikswapinfo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Buy any token using Kwikswap.info`}</h1>
    <p>{`Using a `}<a parentName="p" {...{
        "href": "http://app.kwikswap.org"
      }}>{`app.kwikswap.org`}</a>{` iFrame integration, you can now directly buy and sell any token from the exchange info page. If you are a hardcore DAI trader you might consider bookmarking this page for a one stop shop for data, analytics and trading.`}</p>
    <p>{`info.Kwikswap.org automatically shows all tokens on the Kwikswap protocol as long as there is at least 0.1 ETH in liquidity. This means it can be used to trade tokens that are not shown on the default list of app.kwikswap.org. We highly recommend you verify the address of any tokens traded.`}</p>
    <h1 {...{
      "id": "data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#data",
        "aria-label": "data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Data!`}</h1>
    <p>{`The updated info.Kwikswap.org was built using data from `}<a parentName="p" {...{
        "href": "https://thegraph.com/"
      }}>{`The Graph`}</a>{`. We’ve worked closely with `}<a parentName="p" {...{
        "href": "https://thegraph.com/"
      }}>{`The Graph`}</a>{` team to upgrade the `}<a parentName="p" {...{
        "href": "https://thegraph.com/explorer/subgraph/graphprotocol/kwikswap"
      }}>{`Kwikswap subgraph`}</a>{` to support for better views, historical data and transactions. We’ve added historical data for kwikswap as a whole with daily volumes and liquidity over time.`}</p>
    <p><em parentName="p">{`If you are building a kwikswap data tool you may consider building off `}<a parentName="em" {...{
          "href": "https://github.com/graphprotocol/kwikswap-subgraph"
        }}>{`the new subgraph.`}</a></em></p>
    <h1 {...{
      "id": "ui-and-ux-polish",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ui-and-ux-polish",
        "aria-label": "ui and ux polish permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`UI and UX polish`}</h1>
    <p>{`Along with a fresh coat of paint, we’ve updated the UI to better support denser data views and a consistent layout that flexes smoothly to a mobile view. All the prices denominated in ETH can now be swapped for USD with a toggle in the top right and we’ve cleaned up the units across the site.`}</p>
    <p>{`The charts are now easier to read and have a tab view to switch between data and each token page will now extract a color from the token to apply a unique style.`}</p>
    <h1 {...{
      "id": "upcoming-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#upcoming-features",
        "aria-label": "upcoming features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Upcoming Features`}</h1>
    <p>{`We’ve got a lot planned for this site in the future…`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Liquidity Provider specific pages and support`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Data around holdings, ROI, etc`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Data export (CSV)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Top LPs`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Top performing pools`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Live data for transactions and prices`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`More statistics, rankings, insights`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Exchange health statistics`}</p>
      </li>
    </ul>
    <p>{`If you have any ideas for things you’d like to see that isn’t on this list we’d love to hear.`}</p>
    <hr></hr>
    <p>{`You can check out the repo on `}<a parentName="p" {...{
        "href": "https://github.com/kwikswap/kwikswap-subgraph"
      }}>{`github`}</a>{` if you’d like to explore how everything works.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      